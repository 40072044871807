import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './Page3.module.css';

const Page3 = ({ setCurrentPage, formData, setFormData }) => {
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleNextStep = () => {
    setFormData({
      ...formData,
      AIranking: candidates
    });
    setCurrentPage('Page4');
  };

  useEffect(() => {
    const fetchCandidates = async () => {
      try {
        const response = await axios.post('/api/ai/rank_candidates', {
          priorities: formData.features.map(feature => feature.feature),
          importances: formData.features.map(feature => feature.priority),
        });
        setCandidates(response.data.candidates);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCandidates();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return (
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <p>Please wait while the AI ranks the candidates<span className={styles.pulsingCircle}></span></p>
        </div>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <h3>AI Ranking</h3>
        <div className={styles.container}>
          {candidates.map((candidate, index) => (
            <div className={styles.card} key={index}>
              <p>Candidate: {candidate.candidate}</p>
              <p>Ranking: {candidate.ranking}</p>
              <p>Explanation: {candidate.explanation}</p>
            </div>
          ))}
        </div>
      </div>
      <button className={styles.button} onClick={handleNextStep}>
        CONTINUE
      </button>
    </div>
  );
};

export default Page3;